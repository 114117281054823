class VaultDataItem {
    constructor({
                    clientSpecified: {
                        type, uac, tag1, tag2, tag3, tag4, tag5, ltag1, ltag2, userName, data
                    },
                    creator: {
                        firstName,
                        lastName,
                        emailAddress
                    },
                    id,
                    fileInfo,
                    createdOn,
                    updatedOn
                }) {
        this.type = type;
        this.id = id;
        this.uac = uac;
        this.tag1 = tag1;
        this.tag2 = tag2;
        this.tag3 = tag3;
        this.tag4 = tag4;
        this.tag5 = tag5;
        this.ltag1 = ltag1;
        this.ltag2 = ltag2;
        this.createdOn = createdOn;
        this.updatedOn = updatedOn;
        this.data = data;
        this.fileInfo = fileInfo;

        this.firstName = firstName;
        this.lastName = lastName;
        this.emailAddress = emailAddress;
    }
}
